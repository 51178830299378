export const MySkillsDescription = {
  title: 'My Skills',
  description:
    "Throughout my journey in software development, I've honed a robust skillset in web development, focusing on standards, reusability, scalability, and user experience. My expertise extends to building enterprise-level applications where performance, modularity, and intuitive design are paramount.",
};

export const AllTechSkills = [
  'Javascript',
  'Typescript',
  'Python',
  'HTML',
  'CSS',
  'Node',
  'Express.js',
  'Nest.js',
  'React',
  'React-Native',
  'Next',
  'React-Query',
  'Apollo',
  'GraphQL',
  'Redux',
  'Effector',
  'mobX',
  'Jest',
  'Vitest',
  'Socket.io',
  'SSE',
  'Webpack',
  'Sass/Scss',
  'Tailwind',
  'Ant-Design',
  'Mui',
  'Bootstrap',
  'Styled-components',
  'MongoDB',
  'Git',
];
